export default function (el) {

    el.innerHTML = 'I\'m a scoped function. Try clicking me.';

    const clickHandler = e => {
        e.preventDefault();
        el.innerHTML = 'Wow, it worked! And I won\'t go bonkers w/ HMR, either!';
    };

    el.addEventListener('click', clickHandler);

    this.destroy = () => {
        el.removeEventListener('click', clickHandler);
    };

}
