export default class ClassInstanceComponent {

    constructor(el) {
        this.el = el;
        this.clickHandler = this.clickHandler.bind(this);
        this.init();
    }

    init() {
        this.el.innerHTML = 'I am a class based component!';
        this.el.addEventListener('click', this.clickHandler);
    }

    destroy() {
        this.el.removeEventListener('click', this.clickHandler);
    }

    beforeDispose() {
        console.info('Oh damn, I\'m just about to be reloaded!!!');
    }

    clickHandler(e) {
        e.preventDefault();
        alert('Hello world!');
    }

}
