import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const $stickyBar = $el.find('[data-sticky-bar]');
    const $searchInput = $el.find('[data-search-input]');
    const $searchButton = $el.find('[data-search-button]');
    
    let searchIsVisible = false;

    const toggleMenu = () => {
        $el.toggleClass('-menu-open');
    };

    const init = () => {
        $el.on('click', '[data-menu-toggle]', e => {
            e.preventDefault();
            Dispatch.emit(eventKeys.TOGGLE_MENU);
            toggleMenu();
        });
        
        $searchButton.on('click', e => {
            if (!searchIsVisible) {
                e.preventDefault();
                $searchInput.removeClass('d-n');
                $searchInput.focus();
                searchIsVisible = true;
            }
        });
        
        gsap.to($stickyBar.get(0), { 
            duration: 0.2, 
            y: 0,
            ease: 'Sine.easeOut',
            scrollTrigger: {
                //markers: true,
                trigger: $('#top').get(0),
                endTrigger: $('#bottom').get(0),
                start: "top -200px",
                end: "top 70%",
                toggleActions: "play reverse play reverse"
              },
        });

        Dispatch.on(eventKeys.TOGGLE_MENU, toggleMenu);
    };

    const destroy = () => {
        Dispatch.off(eventKeys.TOGGLE_MENU, toggleMenu);
    };

    return {
        init,
        destroy
    };

};
