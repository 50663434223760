import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    let isOpen = false;

    
    const toggleSubmenu = $trigger => {
        const $items = $el.find('[data-item]');
        
        if (isOpen) {
            $items.each(item => {
                const $item = $(item);
                
                if (!$item.hasClass('-selected-leaf') && !$item.hasClass('-selected-branch')) {
                    gsap.to($item.nodes, { duration: 0.05, opacity: 0, ease: 'Sine.easeOut' });
                    gsap.to($item.nodes, { duration: 0.2, height: 0, ease: 'Sine.easeOut', onComplete: () => {
                        $item.css({ height: '', opacity: '' });
                    } });
                }
            });
        
            $el.removeClass('-is-expanded');
        } else {
            $items.each(item => {
                const $item = $(item);
                
                if (!$item.hasClass('-selected-leaf') && !$item.hasClass('-selected-branch')) {
                    $item.css({ height: 'auto' });
                    const targetHeight = $item.height();
                    $item.css({ height: 0, opacity: 0 });
                    
                    gsap.to($item.nodes, { duration: 0.5, height: targetHeight, ease: 'Quint.easeOut', onComplete: () => {
                        $item.height('auto');
                    } });
                    gsap.to($item.nodes, { duration: 0.5, delay: 0.3, opacity: 1, ease: 'Sine.easeOut' });
                }
            });

            $el.addClass('-is-expanded');
        }
        
        isOpen = !isOpen;
    };
    
    const init = () => {
        $el.on('click', '[data-submenu-toggle]', e => {
            e.preventDefault();
            toggleSubmenu();
        });
    };

    const destroy = () => {
    
    };

    return {
        init,
        destroy
    };

};
