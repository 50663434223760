import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const $svg = $el.find('svg');
    const $container = $svg.parent();
    
    const onResize = () => {
        const targetHeight = $container.height();
        const targetWidth = targetHeight * (564 / 405);
        $svg.css({ width: targetWidth, height: targetHeight });
    };
    
    const init = () => {
        onResize();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
