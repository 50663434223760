import $ from '@vaersaagod/tools/Dom';

export default el => {
    const $el = $(el);
    
    const init = () => {
        $el.find('[data-read-more-toggle]').on('click', e => {
            e.preventDefault();
            $el.find('[data-text-truncated]').css({ display: 'none' });
            $el.find('[data-text-full]').css({ display: 'block' });
            $el.find('[data-read-more-toggle]').css({ display: 'none' });
        });
    };

    const destroy = () => {
        $el.find('[data-read-more-toggle]').off('click');
    };

    return {
        init,
        destroy
    };

};
