/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const OPEN_PUBLICATION_MODAL = 'OPEN_PUBLICATION_MODAL';
export const DOM_UPDATED = 'DOM_UPDATED';

