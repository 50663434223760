<template>
    <div class="container">
        <p>{{ message }}</p>
        <p v-if="timestamp" class="mt-10">
            {{ timestamp }}
        </p>
    </div>
</template>

<script>
    export default {
        name: "VueExample",
        props: {
            hasClicked: Boolean
        },
        watch: {
            hasClicked() {
                this.message = 'Hihi, jeg fikk også med meg at du klikka nå';
            }
        },
        data() {
            return {
                timestamp: null,
                message: 'Jeg er et eksempel på en såkalt Single File Component, som ligger inni Vue-komponenten :)'
            };
        },
        mounted() {
            this.interval = setInterval(() => this.timestamp = new Date().getTime(), 500);
        },
        destroyed() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        font-size: 13px;
    }
</style>
