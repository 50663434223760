export default el => {

    const clickHandler = e => {
        e.preventDefault();
        alert('Hi there');
    };

    const init = () => {
        el.addEventListener('click', clickHandler);
        el.innerHTML = 'Foo bar baz! I \'m revealing an API for u!';
    };

    const destroy = () => {
        el.removeEventListener('click', clickHandler);
    };

    return {
        init,
        destroy
    };

};
