import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const $body = $('body');
    const $bg = $el.find('[data-stikcy-bar-color-bg]');
    
    let hasColorBgs = false;
    
    const createBg = () => {
        $bg.empty();
        
        const $colorBlocks = $body.find('[data-bg-block]');
        
        hasColorBgs = $colorBlocks.length > 0;
        
        $colorBlocks.each(block => {
            const $block = $(block);
            let $fauxBlock = $('<div></div>');
            $fauxBlock.css({ position: 'absolute', width: '100%', left: 0, height: $block.height(), top: $block.offset().top, backgroundColor: $block.css('background-color') });
            $bg.append($fauxBlock);
        });
        
        position();
    };
    
    const position = () => {
        if (hasColorBgs) {
            $bg.css({ transform: 'translateY(-' +  Viewport.scrollTop + 'px)' });
        }
    };
    
    const onResize = () => {
        createBg();
    };
    
    const onScroll = () => {
        position();
    };
    
    const onDomUpdated = () => {
        createBg();
    };
    
    const init = () => {
        createBg();
        
        Dispatch.on(eventKeys.DOM_UPDATED, onDomUpdated);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Dispatch.off(eventKeys.DOM_UPDATED, onDomUpdated);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
