import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const SVG_REAL_WIDTH = 773;
    
    const $el = $(el);
    const $svg = $el.find('svg');
    const $widthGuide = $el.find('[data-width-guide]');
    
    const getSquareWidth = () => {
        const widthRatio = $widthGuide.width() / $el.width();
        return SVG_REAL_WIDTH * widthRatio;
    };
    
    const init = () => {
        gsap.to($svg.find('#bar1MaskRect').get(0), { delay: 0.5, duration: 1, attr: { y: 270 }, ease: 'quart.inOut' });
        gsap.to($svg.find('#squareMaskRect').get(0), { delay: 1.6, duration: 1, attr: { width: getSquareWidth() }, ease: 'elastic.out(0.8, 1)' });
        gsap.to($svg.find('#bar2MaskRect').get(0), { delay: 2.2, duration: 1.4, attr: { y: 0 }, ease: 'quart.inOut' });
        
        Viewport.on('resize', onResize);
    };
    
    const onResize = () => {
        gsap.to($svg.find('#squareMaskRect').get(0), { delay: 0, duration: 1, attr: { width: getSquareWidth() }, ease: 'elastic.out(0.8, 1)' });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
