import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const $sideColumn = $el.find('[data-side-column]');

    const PIN_ENABLE_BREAKPOINT = 1000;

    let scrollTrigger = null;

    const onBreakpoint = (data) => {
        if (scrollTrigger === null && data.detail.current.size >= PIN_ENABLE_BREAKPOINT) {
            createScrollTrigger();
        }
        if (scrollTrigger !== null && data.detail.current.size < PIN_ENABLE_BREAKPOINT) {
            killScrollTrigger();
        }
    };

    const createScrollTrigger = () => {
        if (scrollTrigger === null) {
            const targetHeight = $sideColumn.height() + 126;

            if (Viewport.height > targetHeight + 60) {
                scrollTrigger = ScrollTrigger.create({
                    //markers: true,
                    trigger: $el.get(0),
                    start: "top 120px",
                    end: () => {
                        return `bottom ${targetHeight}px`
                    },
                    pin: $sideColumn.get(0),
                    pinSpacing: false,
                    //anticipatePin: 1
                });
                
                requestAnimationFrame(() => {
                    scrollTrigger.refresh();
                });
            }
        } else {
            scrollTrigger.refresh();
        }
    };

    const killScrollTrigger = () => {
        if (scrollTrigger !== null) {
            scrollTrigger.kill();
            scrollTrigger = null;
        }
    };
    
    const onDomUpdated = () => {
        if (scrollTrigger !== null) {
            scrollTrigger.refresh();
        }
    };

    const init = () => {
        if (Viewport.breakpoint.size >= PIN_ENABLE_BREAKPOINT) {
            createScrollTrigger();
        }
        
        Dispatch.on(eventKeys.DOM_UPDATED, onDomUpdated);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Dispatch.off(eventKeys.DOM_UPDATED, onDomUpdated);
        Viewport.off('breakpoint', onBreakpoint);
        killScrollTrigger();
    };

    return {
        init,
        destroy
    };

};
