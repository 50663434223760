import $ from '@vaersaagod/tools/Dom';
import Vue from 'vue';
import Dispatch from "@vaersaagod/tools/Dispatch";
import request from "@vaersaagod/tools/request";
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

/**
 * @param el
 * @returns {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
export default (el, props) => new Vue({
    el,
    props,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {},
    data: {
        publicationData: null,
        isSubmitting: false,
        isCompleted: false,
        errors: [],
        fieldErrors: {},
        
        category: '',
        companyName: '',
        orgNo: '',
        numEmployees: '',
        contactPerson: '',
        contactPersonPhone: '',
        email: '',
        address: '',
        postNrSted: '',
        ehfInvoice: '',
        invoiceAddress: '',
        invoiceRef: '',
        invoiceEmail: '',
        organization: '',
        confirmTerms: '',
        
        mandatoryFields: [
            'category',
            'companyName',
            'numEmployees',
            'contactPerson',
            'contactPersonPhone',
            'address',
            'postNrSted',
            'organization',
        ]
    },
    watch: {

    },
    methods: {
        onSubmit() {
            this.submit();
        },

        reset() {
            this.isCompleted = false;
            this.errors = [];
            this.fieldErrors = {};
            
            this.category = '';
            this.companyName = '';
            this.orgNo = '';
            this.numEmployees = '';
            this.contactPerson = '';
            this.contactPersonPhone = '';
            this.email = '';
            this.address = '';
            this.postNrSted = '';
            this.ehfInvoice = '';
            this.invoiceAddress = '';
            this.invoiceRef = '';
            this.invoiceEmail = '';
            this.organization = '';
            
            this.confirmTerms = '';
        },

        submit() {
            if (this.validateForm()) {
                let data = {
                    category: this.category,
                    companyName: this.companyName,
                    orgNo: this.orgNo,
                    numEmployees: this.numEmployees,
                    contactPerson: this.contactPerson,
                    contactPersonPhone: this.contactPersonPhone,
                    email: this.email,
                    address: this.address,
                    postNrSted: this.postNrSted,
                    ehfInvoice: this.ehfInvoice,
                    invoiceAddress: this.invoiceAddress,
                    invoiceRef: this.invoiceRef,
                    invoiceEmail: this.invoiceEmail,
                    organization: this.organization,
                };

                data[window.csrfTokenName] = window.csrfTokenValue;

                this.isSubmitting = true;

                request
                    .post(props.actionUrl)
                    .type('application/json')
                    .send(JSON.stringify(data))
                    .then(res => {
                        const { status, body } = res || {};

                        //console.log(status, body);

                        if (status === 200 && body) {
                            // Do something
                            if (body.success !== undefined && body.success === true) {
                                this.isCompleted = true;
                                setTimeout(() => {
                                    Dispatch.emit(eventKeys.DOM_UPDATED);
                                }, 50);
                            } else {
                                this.errors = body.errors !== undefined ? body.errors : ['En ukjent feil oppstod.']
                            }
                        } else {
                            this.errors = ['En feil oppstod.'];
                        }

                        this.isSubmitting = false;
                    }).catch(error => {
                    this.errors = [error];
                });
            } else {
                setTimeout(() => {
                    const $firstError = $($(this.$refs.registrationForm).find('.-field-error').get(0));
                    if ($firstError.length > 0) {
                        gsap.to(window, { duration: 0.7, scrollTo: Math.max($firstError.offset().top - 200, 0), ease: 'Quart.easeInOut' });
                    }
                }, 30)
            }
        },

        validateForm() {
            this.errors = [];
            this.fieldErrors = {};

            if (this.email === '' || !this.validEmail(this.email)) {
                this.fieldErrors.email = ['Du må fylle inn en gyldig e-postadresse.']
            }
            
            if (this.orgNo === '' || this.orgNo.length !== 9) {
                this.fieldErrors.orgNo = ['Organisasjonsnummer må være ni siffer.']
            }
            
            this.mandatoryFields.forEach(val => {
                if (this[val] === '') {
                    this.fieldErrors[val] = ['Feltet er obligatorisk.']
                }
            });

            if (this.confirmTerms === '') {
                this.fieldErrors.confirmTerms = ['Du må godkjenne våre vilkår']
            }

            return !(Object.keys(this.fieldErrors).length > 0 || this.errors.length > 0);
        },
        
        validEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
    mounted() {

    },
    destroyed() {

    }
});
