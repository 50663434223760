import Vue from 'vue';

import VueExample from "../../vue/VueExample";

/**
 * @param el
 * @returns {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
export default el => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {
        VueExample,
    },
    data: {
        message: 'Hei, jeg er en Vue.js-basert komponent :)',
        numClicks: 0
    },
    methods: {
        onClick() {
            this.numClicks += 1;
            this.message = [
                'Oi, så flink du er til å klikke!',
                'Dette går utrolig bra',
                'Du klikker som bare dét, altså',
                'Nå begynner det å bli nok',
                'Nå holder det altså'
            ][this.numClicks - 1] || 'Stopp. Å. Klikke!';
        }
    },
    mounted() {
        console.info('Vue component mounted');
    },
    destroyed() {
        console.info('Vue component destroyed');
    }
});
