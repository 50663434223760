import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import request from '@vaersaagod/tools/request';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $list = $el.find('[data-news-list]');
    const $pagination = $el.find('[data-news-pagination]');
    const $quickSearchWrap = $el.find('[data-quick-search-wrap]');
    const $quickSearchLink = $quickSearchWrap.find('a');
    const $quickSearchTerm = $el.find('[data-quick-search-term]');
    
    const requestUrl = props.listUrl;
    const searchUrl = props.searchBaseUrl;
    let currentTags = [];
    let currentTagTitles = [];
    
    const doLoad = () => {
        gsap.to($list.nodes, { duration: 0.1, opacity: 0 });

        request
           .get(requestUrl)
           .query({ tags: currentTags.join(',') })
           .then(res => {
               if (res.statusCode === 200) {
                   parseData(res.text);
               } else {
                   // todo : feilmelding
               }
           });
    };
    
    const parseData = data => {
        const $data = $(data);
        const $newList = $data.find('[data-news-list]');
        const $newPagination = $data.find('[data-news-pagination]');

        $list.empty().append($newList.nodes);
        $pagination.empty().append($newPagination.nodes);
        
        gsap.to($list.nodes, { duration: 0.4, opacity: 1 });
    };
    
    const toggleTag = $trigger => {
        if ($trigger.hasClass('-is-active')) {
            $trigger.removeClass('-is-active');
        } else {
            $trigger.addClass('-is-active');
        }
        
        currentTags = [];
        currentTagTitles = [];
        
        $el.find('[data-news-list-tag].-is-active').each(el => {
            currentTags.push($(el).data('news-list-tag'));
            currentTagTitles.push($(el).data('news-list-tag-title'));
        });
        
        if (currentTags.length > 0) {
            $quickSearchWrap.css({ display: 'block', opacity: 0 });
            gsap.to($quickSearchWrap.nodes, { delay: 0.6, duration: 0.4, opacity: 1 });
            const searchString = currentTagTitles.join(' ');
            $quickSearchTerm.text(searchString);
            $quickSearchLink.attr('href', searchUrl + '=' + searchString)
        } else {
            $quickSearchWrap.css({ display: 'none' });
        }
        
        doLoad();
    };
    
    const init = () => {
        $el.on('click', '[data-news-list-tag]', e => {
            e.preventDefault();
            toggleTag($(e.triggerTarget));
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
