import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';
//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const $scroller = $el.find('[data-menu-scroller]');
    const $bg = $el.find('[data-menu-bg]');
    const $top = $el.find('[data-menu-top-bar]');
    const $items = $el.find('[data-menu-items]');
    
    let isOpen = false;
    let $openTrigger = null;
    let scrollbarOffset = 0;

    const openMenu = () => {
        $el.css({ height: '100%' });
        $bg.css({ height: 0 });
        $items.css({ opacity: 0, left: scrollbarOffset });
        $scroller.css('overflow-y', 'scroll');
        
        gsap.to($bg.get(0), { duration: 0.7, height: '100vh', ease: 'expo.out' });
        gsap.to($items.get(0), { duration: 0.5, delay: 0.3, opacity: 1, ease: 'sine.out' });
        
        if (Viewport.scrollTop > 0) {
            $top.css({ opacity: 0 });
            gsap.to($top.get(0), { duration: 0.5, delay: 0.3, opacity: 1, ease: 'sine.out' });
        }
        
        document.addEventListener('keyup', onKeyUp);
        //disableBodyScroll($scroller.get(0), { reserveScrollBarGap: true });
        isOpen = true;
    };
    
    const closeMenu = () => {
        gsap.to($items.get(0), { duration: 0.1, opacity: 0 });
        gsap.to($bg.get(0), { duration: 0.3, height: 0, ease: 'sine.in', onComplete: () => {
            $scroller.css('overflow-y', '');
            $items.css({ left: '' });
            $el.css({ height: 0 });
            document.removeEventListener('keyup', onKeyUp);
        } });
        
        //enableBodyScroll($scroller.get(0));
        isOpen = false;
    };
    
    const toggleMenu = () => {
        if (isOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };
    
    const onKeyUp = e => {
        const key = e.keyCode || e.which;
        
        if (isOpen && key === 27) {
            closeMenu();
        }
    };
    
    const toggleSubmenu = $trigger => {
        $trigger.toggleClass('-is-open');
        
        const $submenu = $trigger.parent().parent().find('[data-submenu]');
        const $submenuInner = $submenu.find('[data-submenu-inner]');

        if ($submenu.hasClass('-is-open')) {
            gsap.to($submenuInner.get(0), { duration: 0.1, opacity: 0, ease: 'Sine.easeOut' });
            gsap.to($submenu.get(0), { duration: 0.5, height: 0, ease: 'Quint.easeOut' });
            $openTrigger = null;
        } else {
            if ($openTrigger !== null) {
                toggleSubmenu($openTrigger);
            }
            
            $submenu.css({ height: 'auto' });
            const targetHeight = $submenu.height();
            $submenu.css({ height: 0 });
            $submenuInner.css({ opacity: 0 });
            
            gsap.to($submenuInner.get(0), { delay: 0.2, duration: 0.8, opacity: 1, ease: 'Sine.easeOut' });
            gsap.to($submenu.get(0), { duration: 0.5, height: targetHeight, ease: 'Quint.easeOut', onComplete: () => {
                $submenu.css({ height: 'auto' });
            }});
            
            $openTrigger = $trigger;
        }
        
        $submenu.toggleClass('-is-open');
    };
    
    const init = () => {
        const startWidth = $items.width();
        $scroller.css('overflow-y', 'scroll');
        const newWidth = $items.width();
        $scroller.css('overflow-y', '');
        scrollbarOffset = startWidth - newWidth;
        
        const $submenus = $el.find('[data-submenu]').css({ height: 0 });
        
        $el.css({ height: 0 });
        $el.on('click', '[data-submenu-toggle]', e => {
            e.preventDefault();
            toggleSubmenu($(e.triggerTarget));
        });
        
        $scroller.on('click', e => {
            const { target } = e;
            if (target === $scroller.get(0)) {
                closeMenu();
            }
        });
        
        Dispatch.on(eventKeys.TOGGLE_MENU, toggleMenu);
    };

    const destroy = () => {
        $el.off('click');
            
        Dispatch.off(eventKeys.TOGGLE_MENU, toggleMenu);
        document.removeEventListener('keyup', onKeyUp);
    };

    return {
        init,
        destroy
    };

};
