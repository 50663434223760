import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    let isOpen = false;
    
    const toggleFactBox = $trigger => {
        const targetId = $trigger.attr('href');
        const $target = $(targetId);

        if (isOpen) {
            const startHeight = $target.height();
            $target.css({ maxHeight : '' });
            const targetHeight = $target.height();
            $target.css({ maxHeight : 'none' });
            
            gsap.set($target.get(0), { height: startHeight });
            gsap.to($target.get(0), { duration: 0.4, ease: 'quart.inOut', height: targetHeight, onComplete: () => {
                $target.css({ maxHeight : '', height: '' });
            } });
            
            $el.removeClass('-is-opened');
        } else {
            const startHeight = $target.height();
            $target.css({ maxHeight : 'none' });
            const targetHeight = $target.height();
            
            gsap.set($target.get(0), { height: startHeight });
            gsap.to($target.get(0), { duration: 0.4, ease: 'quart.inOut', height: targetHeight, onComplete: () => {
                $target.css({ maxHeight : 'none', height: '' });
            } });
            
            $el.addClass('-is-opened');
        }
        
        isOpen = !isOpen;
    };
    
    const init = () => {
        $el.on('click', '[data-factbox-toggle]', e => {
            e.preventDefault();
            toggleFactBox($(e.triggerTarget));
        });
    };

    const destroy = () => {
        $el.off('click', '[data-factbox-toggle]');
    };

    return {
        init,
        destroy
    };

};
