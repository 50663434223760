import $ from '@vaersaagod/tools/Dom';
import Vue from 'vue';
import Dispatch from "@vaersaagod/tools/Dispatch";
import request from "@vaersaagod/tools/request";

import * as eventKeys from '../lib/events';

/**
 * @param el
 * @returns {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
export default (el, props) => new Vue({
    el,
    props,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {},
    data: {
        publicationData: null,
        step: 1,
        isSubmitting: false,
        isMemberToggle: null,
        errors: [],
        fieldErrors: {},
        email: '',
        companyName: '',
        orgNo: '',
        contactPerson: '',
        phone: '',
        address: '',
        postalCode: '',
        city: '',
        confirmTerms: '',
        isMember: null
    },
    watch: {
        isMemberToggle() {
            this.isMember = this.isMemberToggle;
        }
    },
    methods: {
        onOpenModal(key, data) {
            this.reset();
            this.publicationData = data;
            this.$refs.modal.style.display = 'block';
        },
        onCloseClick(e) {
            e.preventDefault();
            this.$refs.modal.style.display = 'none';
            this.reset();
        },
        onStepClick() {
            this.errors = [];
            this.fieldErrors = {};
            
            switch (this.step) {
                case 1:
                    if (this.isMemberToggle === null) {
                        this.fieldErrors.isMemberToggle = ['Feltet er obligatorisk.'];
                    } else {
                        this.step = 2;
                        this.$nextTick(() => {
                            $('#publication-modal-email').focus();
                        });
                    }
                    break;
                case 2:
                    this.submit();
                    break;
            }
        },
        onSubmit() {
            this.submit();
        },

        reset() {
            this.publicationData = null;
            this.step = 1;
            this.isMemberToggle = null;
            this.errors = [];
            this.fieldErrors = {};
            this.email = '';
            this.companyName = '';
            this.orgNo = '';
            this.contactPerson = '';
            this.phone = '';
            this.address = '';
            this.postalCode = '';
            this.city = '';
            this.confirmTerms = '';
            this.isMember = null;
        },
        submit() {
            if (this.validateForm()) {
                let data = {
                    publicationUid: this.publicationData.uid,
                    email: this.email,
                    companyName: this.companyName,
                    orgNo: this.orgNo,
                    contactPerson: this.contactPerson,
                    phone: this.phone,
                    address: this.address,
                    postalCode: this.postalCode,
                    city: this.city,
                    isMember: this.isMember,
                };

                data[props.csrfName] = props.csrfToken;

                this.isSubmitting = true;

                request
                    .post(props.actionUrl)
                    .type('application/json')
                    .send(JSON.stringify(data))
                    .then(res => {
                        const { status, body } = res || {};
                        
                        //console.log(status, body);

                        if (status === 200 && body) {
                            // Do something
                            if (body.success !== undefined && body.success === true) {
                                this.step = 3;
                            } else {
                                this.errors = body.errors !== undefined ? body.errors : ['En ukjent feil oppstod.']
                            }
                        } else {
                            this.errors = ['En feil oppstod.'];
                        }

                        this.isSubmitting = false;
                    }).catch(error => {
                        this.errors = [error];
                    });
            }
        },
        validateForm() {
            this.errors = [];
            this.fieldErrors = {};

            if (this.email === '' || !this.validEmail(this.email)) {
                this.fieldErrors.email = ['Du må fylle inn en gyldig e-postadresse.']
            }

            if (this.companyName === '') {
                this.fieldErrors.companyName = ['Feltet er obligatorisk.']
            }

            if (this.isMember !== 'yes') {
                if (this.orgNo === '') {
                    this.fieldErrors.orgNo = ['Feltet er obligatorisk.']
                }

                if (this.contactPerson === '') {
                    this.fieldErrors.contactPerson = ['Feltet er obligatorisk.']
                }

                if (this.phone === '') {
                    this.fieldErrors.phone = ['Feltet er obligatorisk.']
                }

                if (this.address === '') {
                    this.fieldErrors.address = ['Feltet er obligatorisk.']
                }

                if (this.postalCode === '') {
                    this.fieldErrors.postalCode = ['Feltet er obligatorisk.']
                }

                if (this.city === '') {
                    this.fieldErrors.city = ['Feltet er obligatorisk.']
                }

                if (this.confirmTerms === '') {
                    this.fieldErrors.confirmTerms = ['Du må godkjenne våre vilkår']
                }
            }

            return !(Object.keys(this.fieldErrors).length > 0 || this.errors.length > 0);
        },
        validEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
    mounted() {
        //console.log(props);
        Dispatch.on(eventKeys.OPEN_PUBLICATION_MODAL, this.onOpenModal);
    },
    destroyed() {
        Dispatch.off(eventKeys.OPEN_PUBLICATION_MODAL);
    }
});
