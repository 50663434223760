import './lib/polyfills';

import $ from '@vaersaagod/tools/Dom';
import lazySizes from './lib/lazysizes';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import Dispatch from '@vaersaagod/tools/Dispatch';

import * as eventKeys from './lib/events';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

/*
*   Bootstrap app
*
 */
const init = () => {
    Viewport.init();
    Viewport.initTabbing();
    Viewport.releaseTabbing();
    Components.init();
    lazySizes.init();
    
    const $body = $('body');
    
    $body.on('click', 'a[data-anchor-link]', e => {
        e.preventDefault();
        
        const $link = $(e.triggerTarget);
        const $target = $($link.attr('href'));
        
        if ($target.length > 0) {
            gsap.to(window, { duration: 0.7, scrollTo: $target.offset().top, ease: 'Quart.easeInOut' });
        }
    });
    
    $body.on('click', 'a[data-publication-download]', e => {
        e.preventDefault();
        const $trigger = $(e.triggerTarget);
        Dispatch.emit(eventKeys.OPEN_PUBLICATION_MODAL, { uid: $trigger.data('publication-uid'), title: $trigger.data('publication-title') });
    });
    
};

require('doc-ready')(init);
