import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    
    const togglePanel = $trigger => {
        const targetId = $trigger.attr('href');
        const $target = $(targetId);
        const $targetContent = $target.find('[data-panel-content]');
        let isOpen = $target.hasClass('-is-opened');
        
        if (isOpen) {
            gsap.to($targetContent.get(0), { duration: 0.4, ease: 'quart.inOut', height: 0 });
            
            $target.removeClass('-is-opened');
        } else {
            $targetContent.css({ height: 'auto' });
            const targetHeight = $targetContent.height();
            
            gsap.set($targetContent.get(0), { height: 0 });
            gsap.to($targetContent.get(0), { duration: 0.4, ease: 'quart.inOut', height: targetHeight, onComplete: () => {
                $target.css({ height: 'auto' });
            } });
            
            $target.addClass('-is-opened');
        }
        
        isOpen = !isOpen;
    };
    
    const init = () => {
        $el.on('click', '[data-panel-toggle]', e => {
            e.preventDefault();
            togglePanel($(e.triggerTarget));
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
